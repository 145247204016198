(function () {
    'use strict';

    angular.module('informaApp')
        .component('profile', {
            templateUrl: 'components/profile/template.html',
            controller: Profile,
            transclude: {
                body: '?profileBody',
                tooltip: '?profileTooltip',
                preBody: '?preBody'
            },
            bindings: {
                passingOptions: '<',
                compare: '<',
                onTabSwitched: '<',
                onEmpty: '<',
                onShow: '<',
                name: '<'
            }
        });

    function Profile(ProfileStore, $q) {
        let storeManager = null;

        this.$onChanges = (changes) => {
            if (changes.name && changes.name.currentValue) {
                storeManager = ProfileStore.getStoreManager(this.name);
            }

            if (this.compare) {
                initData($q, this, storeManager);
            }
        };

        this.changeTab = (tab) => {
            this.currentTab = tab;

            updateActiveTab(this);
            emitEvent(this.onTabSwitched, tab);
        };

        this.closeTab = () => {
            storeManager.remove(x => this.compare(x, this.currentTab));
            this.savedTabs = storeManager.getAll();
            this.currentTab = this.savedTabs[0];

            updateActiveTab(this);

            emitEvent(this.currentTab ? this.onTabSwitched : this.onEmpty, this.currentTab);
        }
    }

    function initData($q, context, storeManager) {
        context.currentTab = getTab(context, storeManager);

        if (!context.currentTab) {
            emitEvent(context.onEmpty);
            return;
        }

        storeManager.add(context.currentTab, context.compare);

        context.savedTabs = storeManager.getAll();

        updateActiveTab(context);

        emitEvent(context.onShow, context.currentTab);
    }

    function getTab(context, storeManager) {
        removeTabIfShoudBeReplaced(context, storeManager);

        const tab = !context.passingOptions
            ? storeManager.find(x => x.isActive)
            : storeManager.find(x => context.compare(x, context.passingOptions));

        return tab || context.passingOptions;
    }

    function removeTabIfShoudBeReplaced(context, storeManager) {
        if (context.passingOptions && context.passingOptions.replace) {
            storeManager.remove(x => context.compare(x, context.passingOptions));
            delete context.passingOptions.replace;
        }
    }

    function updateActiveTab(context) {
        _.forEach(context.savedTabs, x => {
            x.isActive = x === context.currentTab;
        });
    }

    function emitEvent(event, ...args) {
        if (event) {
            event(...args);
        }
    }
})();